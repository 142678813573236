import React, { Suspense } from 'react'
import Trailer from './Trailer';
import Overview from './Overview';
import Border from './Border';
const Screenshot = React.lazy(() => import("./Screenshot"));

function Home() {
    return (
        <div>
            <Trailer />
            <Border />
            <Overview />
            <Suspense fallback={<div>Loading...</div>}>
                <Screenshot />
            </Suspense>
        </div>
    )
}

export default Home
