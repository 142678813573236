import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css';
import { AiOutlineGlobal } from 'react-icons/ai';
import { FaDiscord, FaTelegramPlane, FaTwitter } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { ExternalLink } from 'react-external-link';

function Footer() {
    return (
        <div className='footer bg-5 flex-center'>
            <div className='footer-container-1 flex-center'>
                <img src='images/icon_company_256.png' className='footer-company-icon' alt='company icon' />
            </div>
            <div className='footer-container-2 flex-center'>
                <Link to='/policy' className='footer-link'><p className='weight'>PRIVACY POLICY</p></Link>
                <Link to='/terms' className='footer-link'><p className='weight'>TERMS OF USE</p></Link>
                <Link to='/aboutus' className='footer-link'><p className='weight' >ABOUT US</p></Link>
                <Link to='/support' className='footer-link'><p className='weight'>SUPPORT CENTER</p></Link>
            </div>

            <div className='footer-container-1 flex-center'>
                <IconContext.Provider value={{ className: "icons" }}>
                    <ExternalLink href='https://steaksoft.net'>
                        <AiOutlineGlobal />
                    </ExternalLink>
                    <ExternalLink href='https://discord.gg/fnWjpWUC4U'>
                        <FaDiscord />
                    </ExternalLink>
                    <ExternalLink href='https://twitter.com/SteakSoft'>
                        <FaTwitter />
                    </ExternalLink>
                </IconContext.Provider>
            </div>
        </div>
    )
}

export default Footer
