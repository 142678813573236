import React from 'react'
import './Border.css';

function Border() {
    return (
        <div className='section'>
            <div className='main-container'>
                <div className='flex-center'>
                    <img className='image' alt='company icon' src='images/icon_company.png'></img>
                </div>
            </div>
        </div>
    )
}

export default Border
