import React, { useState, useEffect } from 'react'
import './Trailer.css';
import { Button } from '../components/Button';

function Trailer() {

  const [isMobile, setMobile] = useState(true);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <div className='bg-2 trailer  flex-center'>
      <div className='top-container'>
        <div className='flex-center'>
          <picture className='flex-center'>
            <source srcSet='images/title.avif' type='image/avif' />
            <img style={{ paddingTop: '100px', paddingBottom: '100px' }} className='trailer-img' src="images/title_old.png" alt='game title' />
          </picture>
        </div>
        <div>
          <div className='flex-center'>
            <p className='white trailer-text text-center text-36'>Pixelated 2D Top-down Teambased PvP Game</p>
          </div>
          <div className='flex-center'>
            <p className='white trailer-text text-center text-24'>Borned as a warrior in the world of Stagdraft, CRAFT & GEAR your way up to LOOT for the world Domination</p>
          </div>
          <div className='trailer-btn flex-center'>

<iframe className='video-trailer'
src="https://youtube.com/embed/E-tyjRP2RN4">
</iframe>
          
          </div>
          <div className='trailer-btn flex-center'>
            <Button
              className='btns'
              buttonStyle='btn--primary'
              buttonSize={`btn--${isMobile ? 'large' : 'xlarge'}`}
              linkTo='https://store.steampowered.com/app/2128540/Stagdraft/'
              isExternal={true}
              onClick={() => { }}
            >
              <div className='flex-center'>
                <img src='images/steam_icon.png' width={'50px'} alt='steam_icon' style={{ paddingRight: 20 }} /> <p>PLAY NOW FREE</p>
              </div>
            </Button>
          </div>

        </div>
      </div>
      <div className='bottom-container flex-center'>
        {/*  <iframe title='game trailer' width={isMobile ? '300px' : '480px'} height={isMobile ? '200px' : '360px'}
          src="https://www.youtube.com/embed/5IDAjtAu3Lc">
  </iframe> */}
      </div>
    </div>
  )
}

export default Trailer
