import React from 'react';
import { ExternalLink } from 'react-external-link';
import { HashLink as Link } from 'react-router-hash-link';
import './Button.css';

const STYLES = ['btn--primary', 'btn--outline'];

const SIZES = ['btn--medium', 'btn--large', 'btn--small', 'btn--xlarge'];

export const Button = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  linkTo,
  isExternal
}) => {

  const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  return (
    <div>
      {isExternal ? <ExternalLink href={linkTo}>
        <button
          className={`btn champagne ${checkButtonStyle} ${checkButtonSize}`}
          onClick={onClick}
          type={type}
        >
          {children}
        </button>
      </ExternalLink>
        :
        <Link to={linkTo}>
          <button
            className={`btn champagne ${checkButtonStyle} ${checkButtonSize}`}
            onClick={onClick}
            type={type}
          >
            {children}
          </button>
        </Link>}


    </div>
  );
};
