import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';

import PrivacyPolicty from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import AboutUs from './pages/AboutUs';
import GamePrivacyPolicy from './pages/GamePrivacyPolicy';
import Support from './pages/Support';

import Footer from './pages/Footer';
import NavBar from './components/NavBar';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

function App() {

  const firebaseConfig = {
    apiKey: "AIzaSyB9SrVuRi8LiOIi3GLlj1d7oppoDZKuUtU",
    authDomain: "steaksoft-game-website.firebaseapp.com",
    projectId: "steaksoft-game-website",
    storageBucket: "steaksoft-game-website.appspot.com",
    messagingSenderId: "465706238448",
    appId: "1:465706238448:web:673163fea5ad0471c8dd35",
    measurementId: "G-16STY40KNP"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);

  return (
    <Router>

      <NavBar />

      <Routes>
        <Route path='/' exact element={<Home />} />

        <Route path='/policy' exact element={<PrivacyPolicty />} />
        <Route path='/terms' exact element={<Terms />} />
        <Route path='/aboutus' exact element={<AboutUs />} />
        <Route path='/gamePrivacyPolicy' exact element={< GamePrivacyPolicy />} />
        <Route path='/support' exact element={<Support />} />

      </Routes>

      <Footer />

    </Router >
  );
}

export default App;
