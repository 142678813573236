import React from 'react'
import LeftCard from '../components/LeftCard';
import RightCard from '../components/RightCard';
import './Overview.css';

function Overview() {
    return (
        <div className='overview'>
            <div className='background-overview'>
                <div className="overview-card-container bg-10">
                    <LeftCard h1='BATTLES' h2='PvPvE' 
                    
                    text="Equip yourself with best gear, dominate the glorious combatant in the battlefield. Play smart and use teamwork to destroy enemy. 
                    Overcome enemy territory, territories allow your team to accumulate resources and beneficiary spot. 
                    Conquer yours, to outpower your enemy!" />

                </div>
            </div>
            <div className='background-overview'>
                <div className="overview-card-container bg-11">
                    <RightCard h1='CUSTOM' h2='CLASSES & ABILITIES' 

                    text='Players are not limited by any customization: armor, weapons, abilities. 
                    However if correct combination is commited, there would be bonus for the player. 
                    In the end, Its a free for players to create their own build: warrior, ranger, mage, or even hybrid' />
                </div>
            </div>
            <div className='background-overview'>
                <div className="overview-card-container bg-14">

                    <LeftCard h1='GEARS' h2="BUILDING, FARMING & CRAFTING" 

                    text="Produce food, materials, ores to fuel your team’s war power. To become the greatest player, the most deadly weapons requires many resources. Investigate the arena for most precious materials. 
                    However don't forget enemies are looking what are you looking. 
                    Last, Set your defenses, then build it up in style! chests, traps, workshops. " />
                </div>
            </div>
            <div className='background-overview'>

                <div className="overview-card-container bg-15">
                    <RightCard h1='REWARDS' h2='POINTS' 

                    text="Player's reward are determined by any contribution on the match: resource gathering, killing players, building.
                    On Winning, player's will be given random loot in the form of fragment that can be used outside of the game and can be crafted, traded, sold between players.
                    Later fragment can be combined into a single piece and used in in-game combat
                    
                    
                    " />
                </div>
            </div>
        </div>
    )

}

export default Overview
