import React from 'react'
import './Support.css'

function Support() {
    return (
        <div className='supp-bg flex-center page-bg'>
            <div className='supp-container'>
                <h1>Support Center</h1>

                <h2>Email</h2>
                <p>stinkysteak@steaksoft.net or <a href='mailto:stinkysteak@steaksoft.net'>click here</a></p>

                <h2>Chat</h2>
                <p>Discord: <a href='https://discord.gg/fnWjpWUC4U' rel='noreferrer' target='_blank'>click here</a></p>

                <p>More questions? check the available social medias down below</p>

            </div>
        </div>
    )
}

export default Support
