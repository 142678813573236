import React from 'react'
import './RightCard.css'

function RightCard(props) {
    return (
        <div className='rightcard'>
            <div className='rightcard-text-container'>
                <h1 className='title light-orange card-h1'>{props.h1}</h1>
                <h2 className='card-h2 orange'>{props.h2}</h2>
                <p className='card-p'>{props.text}</p>
            </div>
        </div>
    )
}

export default RightCard
