import React from 'react'
import './AboutUs.css';

function AboutUs() {
    return (
        <div className='abt-bg flex-center page-bg'>
            <div className='abt-container'>
                <h1>ABOUT US</h1>

                <p>SteakSoft is a small Indie Game developer company founded by StinkySteak on 2020, It's first game is Blackground : Second Dimension on the PlayStore
                    We are located on Indonesia
                </p>

<p>More Info: https://steaksoft.net</p>

            </div>
        </div>
    )
}

export default AboutUs
