import React, { useEffect, useState } from 'react'
import './NavBar.css';
import { HashLink as Link } from 'react-router-hash-link';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'

function NavBar() {

    const [click, setClick] = useState(false);
    const [isMobile, setMobile] = useState(true);

    const handleClick = () => setClick(!click);

    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 640) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
            <nav>
                <div className='navbar bg-1'>
                    {!isMobile ?
                        <ul>
                            <Link to='/' ><img style={{ paddingTop: '3px' }} alt='game icon' width={'50px'} src='icon_192.png' /></Link>
                            <li><Link to='/' className='nav-links'>HOME</Link></li>
                        </ul>
                        :
                        <div>
                            <div className='navbar background-2 flex-center'>
                                <div className='width-50 height-100 flex-left'>
                                    <Link to='#home' className='navbar-logo' onClick={closeMobileMenu}>
                                        <img alt='game title' width={'50px'} src='icon_192.png' />
                                    </Link>
                                </div>
                                <div className='width-50 height-100 flex-right'>
                                    <div className='flex-left menu-icon' onClick={handleClick}>
                                        {!click ? <AiOutlineMenu fontSize={'42px'} /> : <AiOutlineClose fontSize={'42px'} />}
                                    </div>
                                </div>
                            </div>
                            {click &&
                                <div className='navbar-menu bg-1 '>
                                    <ul className='navbar-ul'>
                                        <li className='li-border'> <Link to='/' className='nav-links'>HOME</Link></li>
                                    </ul>
                                </div>
                            }
                        </div>
                    }
                </div>
            </nav>
        </>
    )
}

export default NavBar
